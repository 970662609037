<template>
  <div>
    <h3>Log</h3>
    <ul class="list-group ordersLog">
      <template v-for="item in log">
        <li
          class="list-group-item ordersLog__item"
          :key="item._id"
          :class="getLineClass(item.label)"
        >
          <span class="ordersLog__time">{{
            $moment(item.time).format("MMM D, h:mm:ss a")
          }}</span>
          |
          {{ item.label }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ORDERS_LOG } from "@/core/services/store/ordersLog.module";
export default {
  name: "OrderLog",
  async mounted() {
    this.$store.dispatch(ORDERS_LOG);
  },
  methods: {
    getLineClass(val) {
      if (val.indexOf("[PAYMENT]") !== -1) {
        return "ordersLog__item--payment-line";
      }
      return "ordersLog__item--order-line";
    }
  },
  computed: {
    ...mapState({
      fetching: ({ ordersLog }) => ordersLog.fetching,
      log: ({ ordersLog }) => ordersLog.ordersLog
    })
  }
};
</script>

<style lang="scss" scoped></style>
